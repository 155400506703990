.custom-card  {
  padding: 10px;
  margin-top: 30px;
  box-shadow: 0px 0px 5px #c9d6dc;
}

.container{
  margin: 10px;
}

.annData{
  white-space: pre-wrap;
  max-height: 400px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.txt{
  width: 100%;
  height: 100%;
  padding: 10px;
  position: relative;
  float: left;
  white-space: normal;
}
.deleteButton{
  position: relative;
  float: right; 
  background-color: white;
}