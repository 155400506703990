
/* xs */
@media screen and (max-width: 576px) {


  .CardImg {
      height: 80px;
  }
  .mainContainer .ant-card {
    height: 255px !important;
    font-size: 12px;
  }

}

/* sm */
@media screen and (min-width: 576px) and (max-width : 768px) {

  .CardImg {
      height: 160px;
    }

 .mainContainer .ant-card {
    height: 340px !important;
  }
}

/* md */
@media screen and (min-width: 768px) and (max-width : 992px) {

  .CardImg {
    height: 180px;
  }

 .mainContainer .ant-card {
    height: 360px !important;
  }
}

/* lg */
@media screen and (min-width: 992px) and (max-width : 1200px) {

  .CardImg {
    height: 180px;
  }

 .mainContainer .ant-card {
    height: 360px !important;
  }
}

/* xl */
@media screen and (min-width: 1200px) and (max-width : 1600px) {

  .CardImg {
    height: 180px;
  }

 .mainContainer .ant-card {
    height: 380px !important;
  }
}

/* xxl */
@media screen and (min-width: 1600px) {

  .CardImg {
    height: 200px;
    }

 .mainContainer .ant-card {
    height: 400px !important;
  }
}
