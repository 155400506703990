.Form {
  position: relative;
    left: 50%;
    transform: translateX(-40%);
    width: 80%;
    align-content: flex-end;
    border: none;
}

.input{
  width: 85%;
}
.card {
  padding: 30px;
  border: solid 1px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  text-align: center;
  background-image: url("./background.jpg");
  width: 25%;
  position: relative;
  float: left;
  height: 700px;
  overflow: auto;
}
.card:hover {
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.08);
}
.card-title {
  margin-top: 20px;
}
.card-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
.card-text .fa {
  font-size: 26px;
}
.avatar {
  align-content: center;
  width: fit-content;
}

.unsub{
  position: relative;
  float: right;
  color: brown
}

